<template>
  <div id="Monitor" class="layer" v-if="monitorVisible">
    <div class="wallMountedDisplay">
      <img src="@/assets/wallMountedDisplay.png" alt="">
      <slot name="monitorContent"></slot>
      <div style="text-align: center;">
        <slot name="btnGroup"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Monitor",
  props: {
    monitorVisible: Boolean,
  },
  data() {
    return {
    
    }
  },

}
</script>

<style lang="stylus" scoped>
.wallMountedDisplay
  position absolute
  left 50%
  top 50%
  transform translate(-50%, -50%)
  img
    margin-bottom 30px
</style>